import lodash from 'lodash'
import { useEffect, useState } from 'react'
import type { RequestType, Requests } from 'rma-shared/requests'
import { AuthService } from '../auth/auth-service'

const BACKEND_URL = process.env.REACT_APP_API_URL || ''

interface RequestOptions {
  delay?: number
  retries?: number
  initialTimeoutDelay?: number
  abortTimeout?: number
  minCompletionTime?: number
}

interface RequestResponse<T extends RequestType> {
  type: T
  data: Requests[T]['output']
}

interface RequestErrorBody {
  errors: { title: string }[]
}

/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
export const useRequest = <T extends RequestType>(
  type: T,
  options: RequestOptions = {},
): [
  (msg: Requests[T]['input']) => Promise<Requests[T]['output']>,
  { loading: boolean; data: Requests[T]['output'] | undefined; error: string | undefined; reset: () => void },
] => {
  const [messageSent, setMessageSent] = useState<Requests[T]['input'] | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Requests[T]['output'] | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [abortController, setAbortController] = useState<AbortController | null>(null)
  const [delayedTimeout, setDelayedTimeout] = useState<number | undefined>(undefined)

  function reset() {
    setData(undefined)
    setError(undefined)
    setLoading(false)
    setAbortController(null)
  }

  async function fetchFunc(msg: Requests[T]['input']): Promise<Requests[T]['output']> {
    if (msg === messageSent) {
      if (data) {
        return data
      }
    }

    if (abortController) {
      abortController.abort()
    }

    const newAbortController = new AbortController()

    setLoading(true)
    setError(undefined)
    setMessageSent(msg)
    setAbortController(newAbortController)

    const retries = options.retries === undefined ? 2 : 1
    let timeoutDelay = options.initialTimeoutDelay || 4000
    let lastError = ''
    let abortTimeoutId: NodeJS.Timeout | null = null
    const state = { timerAborted: false }
    const tStart = Date.now()

    for (let n = 0; n < retries; n += 1) {
      if (n > 0) {
        await new Promise((response) => setTimeout(() => response(null), timeoutDelay))
        timeoutDelay *= 2
      }

      try {
        if (options.abortTimeout) {
          abortTimeoutId = setTimeout(() => {
            if (newAbortController) {
              newAbortController.abort()
              state.timerAborted = true
            }
          }, options.abortTimeout)
        }

        const authorization = await AuthService.getValidAccessToken()

        const response = await fetch(`${BACKEND_URL}/rma`, {
          method: 'POST',
          headers: {
            authorization,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type,
            data: msg,
          }),
          signal: newAbortController.signal,
        })

        if (options.minCompletionTime) {
          const { minCompletionTime } = options
          const tWaited = Date.now() - tStart
          if (tWaited < minCompletionTime) {
            await new Promise((resolve) => setTimeout(resolve, minCompletionTime - tWaited))
          }
        }

        if (response.headers.get('Content-Disposition')?.includes('attachment')) {
          const url = window.URL.createObjectURL(await response.blob())
          const a = document.createElement('a')
          a.href = url
          a.download = response.headers.get('Content-Disposition')?.match(/filename="(.*)"/)?.[1] || 'attachment'
          document.body.appendChild(a)
          a.click()
          a.remove()

          if (abortTimeoutId) {
            clearTimeout(abortTimeoutId)
            abortTimeoutId = null
          }

          setData(undefined)
          setError(undefined)
          setLoading(false)
          setAbortController(null)

          return true as never
        }

        const json = await response.text()
        const jsonData = JSON.parse(json, dateParseJSON) as RequestResponse<T>

        if (response.status === 400) {
          const errorJson = (jsonData as unknown) as RequestErrorBody
          const errorMsg = errorJson.errors[0].title || 'Unknown Error'

          setData(undefined)
          setError(errorMsg)
          setLoading(false)
          setAbortController(null)

          throw new Error(errorMsg)
        }

        if (abortTimeoutId) {
          clearTimeout(abortTimeoutId)
          abortTimeoutId = null
        }

        setData(jsonData.data)
        setError(undefined)
        setLoading(false)
        setAbortController(null)

        return jsonData.data
      } catch (err) {
        if (err instanceof Error) {
          lastError = err.message

          if (err.name === 'AbortError' && !state.timerAborted) {
            throw new Error('Request was aborted')
          }
        } else {
          lastError = ''
        }
      }
    }

    lastError = lastError || 'Unknown Error'

    setData(undefined)
    setMessageSent(undefined)
    setLoading(false)

    if (!state.timerAborted) {
      setError(lastError)
    }

    throw new Error(lastError)
  }

  if (options.delay) {
    return [
      async (msg: Requests[T]['input']) => {
        return new Promise((resolve) => {
          if (delayedTimeout) {
            clearTimeout(delayedTimeout)
          }
          setDelayedTimeout(setTimeout(resolve, options.delay))
        }).then(() => fetchFunc(msg))
      },
      { loading, data, error, reset },
    ]
  }

  return [fetchFunc, { loading, data, error, reset }]
}

export const useQuery = <T extends RequestType>(
  type: T,
  request: Requests[T]['input'],
  options: RequestOptions = {},
) => {
  const [fetch, { data, loading, error }] = useRequest(type, options)
  const [cachedRequest, updateCachedRequest] = useState(request)

  async function doFetch() {
    try {
      await fetch(request)
    } catch (err) {
      console.error(err instanceof Error ? err.message : 'Request Failed')
    }
  }

  useEffect(() => {
    void doFetch()
  }, [cachedRequest])

  if (!lodash.isEqual(cachedRequest, request)) {
    updateCachedRequest(request)
  }

  return { data, loading, error }
}

export async function makeRequest<T extends RequestType>(
  type: T,
  msg: Requests[T]['input'],
): Promise<Requests[T]['output']> {
  const authorization = await AuthService.getValidAccessToken()

  const response = await fetch(`${BACKEND_URL}/rma`, {
    method: 'POST',
    headers: {
      authorization,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type,
      data: msg,
    }),
  })

  if (response.headers.get('Content-Disposition')?.includes('attachment')) {
    const url = window.URL.createObjectURL(await response.blob())
    const a = document.createElement('a')
    a.href = url
    a.download = response.headers.get('Content-Disposition')?.match(/filename="(.*)"/)?.[1] || 'attachment'
    document.body.appendChild(a)
    a.click()
    a.remove()

    return true as never
  }

  const json = await response.text()
  const jsonData = JSON.parse(json, dateParseJSON) as RequestResponse<T>

  return jsonData.data
}

const regexDateISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/

function dateParseJSON(_: string, value: unknown) {
  if (typeof value === 'string') {
    if (regexDateISO.exec(value)) {
      return new Date(value)
    }
  }

  return value
}

export function didUserAbortedError(err: unknown) {
  if (err instanceof Error) {
    if (err.message === 'The user aborted a request.') {
      return true
    }
  }

  return false
}
