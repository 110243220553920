import { AuthService } from '@shared-ui/auth/auth-service'
import { initSentry } from '@shared-ui/utils/sentry'
import '@ubnt/ui-components/styles/normalize.css'
import '@ubnt/ui-components/styles/reset.css'
import 'fontsource-lato/400-italic.css'
import 'fontsource-lato/400-normal.css'
import 'fontsource-lato/700-italic.css'
import 'fontsource-lato/700-normal.css'
import { SsoUserRole } from 'generated/graphql'
import { createRoot } from 'react-dom/client'
import 'rma-frontend-shared/style.css'
import { loadApolloClient } from './apollo'
import { Providers } from './components/Providers'
import { config } from './config'
import './index.css'
import { App } from './rma'

if (config.sentryDSN) {
  initSentry({
    dsn: config.sentryDSN,
    environment: config.env,
    release: config.release,
  })
}

function main() {
  void AuthService.load(SsoUserRole.RmaCustomer, false, () => {
    loadApolloClient()
  })

  const container = document.getElementById('root')!
  const root = createRoot(container)

  root.render(
    <Providers>
      <App />
    </Providers>,
  )
}

main()
