import { CheckmarkIcon } from '@ubnt/icons'
import { cssVariables, Modal, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import styled from 'styled-components'
import { TicketWarrantyStatus } from '../../generated/graphql'
import { Link } from '../Link'
import { Column } from '../SprintShared'

const WarrantyText: Record<TicketWarrantyStatus, string> = {
  [TicketWarrantyStatus.Active]: 'The product is under warranty.',
  [TicketWarrantyStatus.Unvr]: 'The product is eligible for a complimentary replacement period extension.',
}

const WarrantyTextMore: Record<TicketWarrantyStatus, string> = {
  [TicketWarrantyStatus.Active]: '',
  [TicketWarrantyStatus.Unvr]: `We have extended the replacement period of all UNVRs manufactured between October 2019 and November 2020.<br/><br/>This device is still within it&#39;s extended replacement period, so it doesn&#39;t require proof of purchase to be replaced. Please approve this request and send the device to Ubiquiti for further processing.`,
}

interface WarrantyStatusWidgetProps {
  warrantyStatus: TicketWarrantyStatus
}

export function WarrantyStatusWidget({ warrantyStatus }: WarrantyStatusWidgetProps) {
  const [isModalOpen, setModalOpen] = useState(false)

  const isUNVR = warrantyStatus === TicketWarrantyStatus.Unvr

  if (isUNVR) {
    return (
      <WarrantyStatusWidgetContainer className="green">
        <CheckmarkIcon />
        <Column>
          <Text size="body">{WarrantyText[warrantyStatus]}</Text>
          <span style={{ marginTop: '12px' }}>
            <Link onClick={() => setModalOpen(true)}>Learn more</Link>
          </span>
        </Column>

        <Modal
          isOpen={isModalOpen}
          size="small"
          title="UNVR Replacement Period Extension"
          actions={[
            {
              text: <span>Close</span>,
              variant: 'tertiary',
              onClick: () => setModalOpen(false),
            },
          ]}
          onRequestClose={() => setModalOpen(false)}
        >
          <Text size="body">
            <div dangerouslySetInnerHTML={{ __html: WarrantyTextMore[warrantyStatus] }} />
          </Text>
        </Modal>
      </WarrantyStatusWidgetContainer>
    )
  }

  return (
    <WarrantyStatusWidgetContainer className="green">
      <CheckmarkIcon color="#39CC64" width="20" height="20" />
      <Text size="body">{WarrantyText.Active}</Text>
    </WarrantyStatusWidgetContainer>
  )
}

const WarrantyStatusWidgetContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 16px;
  border-radius: 6px;
  color: #000000a6;
  margin-bottom: ${cssVariables['spacing-m']};
  align-items: center;

  & > svg {
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }

  &.blue {
    background: #fafbfb;

    svg {
      color: rgba(0, 111, 255, 1);
    }
  }
  &.green {
    background: #fafbfb;

    svg {
      color: rgba(57, 204, 100, 1);
    }
  }
`
