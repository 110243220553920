import { Button, Buttons, ContentHolder3 } from '@shared-ui/components'
import type { Company } from '@shared/types/company'
import { CompanyType } from '@shared/types/tickets'
import { Text } from '@ubnt/ui-components'
import { useRef } from 'react'
import { SubmitRmaService } from '../../../submit-rma-service'
import { StepHeading } from '../../step-heading'
import { SubmitContainer } from '../../submit-container'
import { AddSellerForm } from './add-seller-form'

export function AddSeller() {
  const submitFormRef = useRef<() => Promise<void>>()

  const handleSubmit = (values: Company) => {
    const seller: Company = {
      id: '',
      logo: null,
      name: values.name || '',
      nameLegal: values.name || '',
      website: values.website || '',
      entityType: CompanyType.reseller,
    }
    SubmitRmaService.setSeller(seller, false)
    SubmitRmaService.backToFlow()
  }

  const handleNext = () => {
    if (submitFormRef.current) void submitFormRef.current()
  }

  return (
    <SubmitContainer>
      <div className="flex column p-16 mt-6 gap-8 relative">
        <StepHeading heading="Add a New Seller" showStepper={false} />

        <Text size="body" color="secondary" className="mb-24">
          Tell us more about this seller.
        </Text>

        <ContentHolder3>
          <AddSellerForm
            setSubmitForm={(submitForm) => {
              submitFormRef.current = submitForm
            }}
            handleSubmit={(values) => handleSubmit(values as Company)}
          />
        </ContentHolder3>

        <Buttons>
          <Button variant="tertiary" onClick={SubmitRmaService.backToFlow} $minWidth="0px">
            Cancel
          </Button>

          <Button variant="primary" onClick={handleNext} $minWidth="0px">
            Save
          </Button>
        </Buttons>
      </div>
    </SubmitContainer>
  )
}
