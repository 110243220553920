import { ChatIcon } from '@ubnt/icons'
import { cssVariables, Loader, Tabs } from '@ubnt/ui-components'
import { useEffect, useState } from 'react'
import type { TicketTabPermission } from 'rma-shared/types/permissions'
import { useRequest } from '../../hooks'
import isPortal from '../../utils/isPortal'
import { Chat } from '../chat/Chat'
import { CustomerSupport } from '../customer-support'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'
import { useShared_Messaging_TicketChatQuery as useQuery } from './__generated__/Messaging'

function Messaging({ ticket }: { ticket: Ticket }) {
  const [selectedTab, setSelectedTab] = useState(MESSAGING_TABS[0].id)
  const [fetch, { data: uiMessagesData, loading: loadingUiMessages, error: uiMessagesError }] = useRequest(
    'customerSupportMessages',
  )
  const ticketId = ticket.id
  const hasUIHelpAvailable = isPortal('FAE') || isPortal('RMA')
  const [showUIHelp, setShowUIHelp] = useState(false)

  useEffect(() => {
    if (hasUIHelpAvailable) {
      void fetch({ ticketId })
    }
  }, [ticketId])

  useEffect(() => {
    if ((uiMessagesData?.messages.length || 0) > 0 && hasUIHelpAvailable) {
      setShowUIHelp(true)
    }
  }, [uiMessagesData])

  if (hasUIHelpAvailable && loadingUiMessages && !showUIHelp) {
    return (
      <div className="flex justify-center">
        <Loader size="small" />
      </div>
    )
  }

  return (
    <>
      {showUIHelp && !ticket.supportByUbiquiti && (
        <Tabs
          selectedTab={selectedTab}
          tabs={MESSAGING_TABS}
          onSelectTab={({ id }) => setSelectedTab(id)}
          variant="capsule"
          stretched
          style={{ marginBottom: cssVariables['spacing-l'] }}
        />
      )}
      {showUIHelp && selectedTab === 'ui-help' && (
        <CustomerSupport
          ticketId={ticket.id}
          sender={{
            id: ticket.customer?.id,
            name: ticket.customer?.fullname || ticket.customerName,
            email: ticket.customer?.email || '',
          }}
          fetch={fetch}
          data={uiMessagesData}
          loading={loadingUiMessages}
          error={uiMessagesError}
        />
      )}
      {(!showUIHelp || selectedTab === 'distributor') && <DistributorChat ticket={ticket} hasTabsAbove={showUIHelp} />}
    </>
  )
}

function DistributorChat({ ticket, hasTabsAbove }: { ticket: Ticket; hasTabsAbove: boolean }) {
  const { data, loading } = useQuery({ variables: { ticketId: ticket.id } })

  if (loading) {
    return (
      <div className="flex justify-center">
        <Loader size="small" />
      </div>
    )
  }

  const chatId = data?.ticket?.chat.id

  if (!chatId) {
    return null
  }

  return <Chat chatId={chatId} hasTabsAbove={hasTabsAbove} />
}

export const MessagingTab = {
  id: 'messaging',
  path: '/messaging',
  icon: <ChatIcon size="navigation" omitSvgMargin />,
  component: Messaging,
  permission: 'ticket-tab-messaging' as TicketTabPermission,
}

const MESSAGING_TABS = [
  {
    id: 'ui-help',
    label: 'UI Help',
  },
  {
    id: 'distributor',
    label: 'Distributor',
  },
]
