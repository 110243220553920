import { TicketStatus } from '@shared/tickets'
import type { CompanyAddressId, CompanyId, NetsuiteFailureCategoryId, TicketId } from '@shared/types/brands'
import React, { createContext, useContext, useState } from 'react'
import type { FileUploadFile } from '../../file-upload'

export interface ExtraData {
  notes?: string
  shippingCompany?: string
  trackingNumber?: string
  failureCategory?: NetsuiteFailureCategoryId
  failureDescription?: string
}

export interface ManageContextState {
  ticketId: TicketId
  ticketStatus: TicketStatus
  files: FileUploadFile[]
  shippingCompany: string
  trackingNumber: string
  failureCategory: NetsuiteFailureCategoryId
  failureDescription: string
  distributorId?: CompanyId | null
  addressId: CompanyAddressId
  notes: string
  replacementMac: string
  refundOption: TicketStatus
  unsavedChanges: boolean
}

interface Context {
  state: ManageContextState
  setup: (ticketId: TicketId, ticketStatus: TicketStatus) => void
  setDistributorId: (distributorId?: CompanyId | null) => void
  setFailureCategory: (failureCategory: NetsuiteFailureCategoryId) => void
  setFailureDescription: (failureDescription: string) => void
  setAddressId: (addressId: CompanyAddressId) => void
  setNotes: (notes: string) => void
  setFiles: (files: FileUploadFile[]) => void
  setShippingInfo: (shippingCompany: string, trackingNumber: string) => void
  setRefundOption: (ticketStatus: TicketStatus) => void
  setReplacementMac: (replacementMac: string) => void
}

const TicketContext = createContext<Context | undefined>(undefined)

function createContextData(): ManageContextState {
  return {
    ticketId: '',
    ticketStatus: TicketStatus.Submitted,
    files: [],
    addressId: '',
    distributorId: null,
    failureCategory: '',
    failureDescription: '',
    shippingCompany: '',
    trackingNumber: '',
    notes: '',
    refundOption: TicketStatus.Shipped,
    replacementMac: '',
    unsavedChanges: false,
  }
}

const defaultContextData = createContextData()

export function TicketProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<ManageContextState>(defaultContextData)

  const ctx: Context = {
    state,
    setup(ticketId: TicketId, ticketStatus: TicketStatus) {
      setState({
        ...createContextData(),
        ticketId,
        ticketStatus,
      })
    },
    setDistributorId(distributorId?: CompanyId | null) {
      setState({ ...state, distributorId, unsavedChanges: true })
    },
    setFailureCategory(failureCategory: NetsuiteFailureCategoryId) {
      setState({ ...state, failureCategory, unsavedChanges: true })
    },
    setFailureDescription(failureDescription: string) {
      setState({ ...state, failureDescription, unsavedChanges: true })
    },
    setAddressId(addressId: CompanyAddressId) {
      setState({ ...state, addressId, unsavedChanges: true })
    },
    setNotes(notes: string) {
      setState({ ...state, notes, unsavedChanges: true })
    },
    setFiles(files: FileUploadFile[]) {
      setState({ ...state, files, unsavedChanges: true })
    },
    setShippingInfo(shippingCompany: string, trackingNumber: string) {
      setState({ ...state, shippingCompany, trackingNumber, unsavedChanges: true })
    },
    setRefundOption(refundOption) {
      setState({ ...state, refundOption, unsavedChanges: true })
    },
    setReplacementMac(replacementMac) {
      setState({ ...state, replacementMac, unsavedChanges: true })
    },
  }

  return <TicketContext.Provider value={ctx}>{children}</TicketContext.Provider>
}

export function useTicket() {
  const context = useContext(TicketContext)
  if (context === undefined) {
    throw new Error('useTicket must be used within a TicketProvider')
  }

  return context
}
