export * from './utils/fiscal'
export * from './utils/time'
export * from './utils/ucare'
export * from './utils/count-items'
export * from './validation/mac-serial'

export const formatPercentage = (value: number, precision = 2) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })

  return formatter.format(value)
}
