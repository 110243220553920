import type { ShippingAddress } from '@shared/types/address'
import type { DeviceId, ShippingAddressId } from '@shared/types/brands'
import type { Company } from '@shared/types/company'
import type { Device } from '@shared/types/devices'
import type { KitPack } from '@shared/types/kit-devices'
import create from 'zustand'
import type { SubmitRmaStep, SubmitSearchType } from './submit-rma-types'

export interface KitReturnDevice {
  index: number
  deviceId: DeviceId | null
  mac: string
  sku: string
  loading: boolean
  errorBe: string
  errorFe: string
  hidden: boolean
}

type SubmitRmaState = {
  flow: SubmitRmaStep[]
  flowIndex: number
  totalSteps: number
  view: {
    curr: SubmitRmaStep
    prev: SubmitRmaStep
  }
  mac: string
  device: Device | null
  currSku: string
  seller: Company | null
  forwardedSeller: Company | null
  suggestedSellerAddress: string | null
  hasSellData: boolean
  shippingAddresses: ShippingAddress[] | null
  shippingAddressId: ShippingAddressId
  shippingAddress: ShippingAddress | null
  shippingAddressEdit: ShippingAddress | null
  shippingAddressNew: ShippingAddress | null
  shippingAddressValidated: ShippingAddress | null
  isOutOfWarranty: boolean
  isOriginalSeller: boolean
  isProofOfPurchaseNeeded: boolean
  isDevicePhotoNeeded: boolean
  isInvalidShippingAddress: boolean
  isInputMac: boolean
  selectedReplacementPart: string | null
  searchType: SubmitSearchType | null
  supportsUICare: boolean
  manualUICare: boolean
  kits: KitPack[]
  kit: KitPack | null
  kitDevices: KitReturnDevice[]
  kitReturnType: string
}

type SubmitRmaFunctions = {
  reset: () => void
  setFlow: (flow: SubmitRmaStep[]) => void
  resetFlow: () => void
  setFlowIndex: (flowIndex: number) => void
  setView: (view: SubmitRmaStep) => void
  replaceAddress: (prevAddressId: ShippingAddressId, addressNew: ShippingAddress) => void
  removeAddress: (addressId: ShippingAddressId) => void
  updateKitReturnDevice: (kitReturnDevice: KitReturnDevice) => void
}

const createInitialState = (): SubmitRmaState => {
  return {
    totalSteps: 0,
    flow: [],
    flowIndex: 0,
    view: {
      curr: 'searchHardware',
      prev: 'searchHardware',
    },
    mac: '',
    device: null,
    currSku: '',
    seller: null,
    forwardedSeller: null,
    suggestedSellerAddress: null,
    shippingAddresses: null,
    shippingAddressId: 0,
    shippingAddress: null,
    shippingAddressEdit: null,
    shippingAddressNew: null,
    shippingAddressValidated: null,
    isOutOfWarranty: false,
    isOriginalSeller: true,
    isProofOfPurchaseNeeded: false,
    isDevicePhotoNeeded: false,
    isInvalidShippingAddress: false,
    isInputMac: true,
    selectedReplacementPart: null,
    searchType: null,
    supportsUICare: false,
    manualUICare: false,
    hasSellData: false,
    kits: [],
    kit: null,
    kitDevices: [],
    kitReturnType: '',
  }
}

export const useSubmitRmaStore = create<SubmitRmaState & SubmitRmaFunctions>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },

  setFlow(flow: SubmitRmaStep[]) {
    return set((state) => {
      return {
        flow,
        flowIndex: 0,
        view: { prev: state.view.curr, curr: flow[state.flowIndex] },
        totalSteps: flow.length,
      }
    })
  },

  resetFlow() {
    set({
      flow: [],
      flowIndex: 0,
      view: {
        curr: 'searchHardware',
        prev: 'searchHardware',
      },
      totalSteps: 0,
    })
  },

  setFlowIndex(flowIndex: number) {
    return set((state) => ({
      flowIndex,
      view: {
        prev: state.view.curr,
        curr: state.flow[flowIndex],
      },
    }))
  },

  setView(view: SubmitRmaStep) {
    return set((state) => ({
      view: {
        prev: state.view.curr,
        curr: view,
      },
    }))
  },

  replaceAddress(prevAddressId: ShippingAddressId, shippingAddressNew: ShippingAddress) {
    return set((state) => {
      if (!state.shippingAddresses) {
        return state
      }

      if (prevAddressId === -1) {
        return {
          shippingAddressNew,
        }
      }

      const index = state.shippingAddresses.findIndex((entry) => entry.id === prevAddressId)
      if (index === -1) {
        return state
      }

      const newAddresses = [...state.shippingAddresses]
      newAddresses[index] = shippingAddressNew

      return {
        shippingAddresses: newAddresses,
        shippingAddressId: state.shippingAddressId === prevAddressId ? shippingAddressNew.id : state.shippingAddressId,
      }
    })
  },

  removeAddress(addressId: ShippingAddressId) {
    return set((state) => {
      if (!state.shippingAddresses) {
        return state
      }

      if (addressId === -1) {
        return {
          shippingAddressNew: null,
          shippingAddressId: state.shippingAddressId === -1 ? 0 : state.shippingAddressId,
        }
      }

      const index = state.shippingAddresses.findIndex((entry) => entry.id === addressId)
      if (index === -1) {
        return state
      }

      const newAddresses = [...state.shippingAddresses]
      newAddresses.splice(index, 1)

      return {
        shippingAddresses: newAddresses,
        shippingAddressId: addressId === state.shippingAddressId ? 0 : state.shippingAddressId,
      }
    })
  },

  updateKitReturnDevice(kitReturnDevice: KitReturnDevice) {
    return set((state) => {
      const kitDevicesNew = [...state.kitDevices]
      kitDevicesNew[kitReturnDevice.index] = kitReturnDevice

      return {
        kitDevices: kitDevicesNew,
      }
    })
  },
}))
