import { WarrantyStatus } from '@shared-ui/generated/graphql'
import { FEDEX_ADDRESS_VALIDATION_COUNTRIES, USE_FEDEX } from '@shared/lib/constants'
import { NotPartOfKitOption } from '@shared/lib/devices/kit-devices'
import { isProofOfPurchaseRequired } from '@shared/lib/utils/checks/is-proof-of-purchase-required'
import type { Company } from '@shared/types/company'
import type { Device } from '@shared/types/devices'
import { KitType } from '@shared/types/devices'
import type { KitPack } from '@shared/types/kit-devices'
import type { CountryCode } from 'libphonenumber-js'
import { KitReturnService } from './kit-return-service'
import type { KitReturnDevice } from './submit-rma-store'
import { useSubmitRmaStore } from './submit-rma-store'
import type { SubmitRmaStep } from './submit-rma-types'
import { SubmitRmaFlow } from './submit-rma-types'

export const SubmitRmaService = {
  reset: () => {
    useSubmitRmaStore.getState().reset()
  },

  setView: (view: SubmitRmaStep) => {
    useSubmitRmaStore.getState().setView(view)
  },

  startFlow: () => {
    const { device, isProofOfPurchaseNeeded, isDevicePhotoNeeded } = useSubmitRmaStore.getState()

    if (!device) {
      return
    }

    const isWithoutSellerFlow =
      device.isHandledByUbiquiti &&
      !isProofOfPurchaseNeeded &&
      !isDevicePhotoNeeded &&
      !device.isSellerInactive &&
      device.warrantyStatus === WarrantyStatus.Active

    useSubmitRmaStore.getState().setFlow(isWithoutSellerFlow ? SubmitRmaFlow.withoutSeller : SubmitRmaFlow.default)
  },

  next: () => {
    const { flow, flowIndex, device } = useSubmitRmaStore.getState()

    if (!device) {
      return
    }

    const nextFlowIndex = flowIndex + 1
    const nextStep = nextFlowIndex < flow.length ? flow[nextFlowIndex] : null
    if (!nextStep) {
      return
    }

    useSubmitRmaStore.getState().setFlowIndex(nextFlowIndex)

    SubmitRmaService.setInvalidShippingAddress(false)
  },

  back: () => {
    const { flow, flowIndex, device } = useSubmitRmaStore.getState()

    if (!device) {
      return
    }

    const prevFlowIndex = flowIndex - 1
    const prevStep = prevFlowIndex >= 0 ? flow[prevFlowIndex] : null
    if (!prevStep) {
      SubmitRmaService.reset()
      return
    }

    useSubmitRmaStore.getState().setFlowIndex(prevFlowIndex)
  },

  backToFlow: () => {
    const { flowIndex } = useSubmitRmaStore.getState()

    useSubmitRmaStore.getState().setFlowIndex(flowIndex)
  },

  setMac: (mac: string) => {
    useSubmitRmaStore.setState({ mac, searchType: null, device: null, isOutOfWarranty: false })
  },

  processDevice(
    device: Device | null,
    params: {
      supportsUICare?: boolean
      manualUICare?: boolean
      kits?: KitPack[]
    } = {},
  ) {
    const prevDevice = useSubmitRmaStore.getState().device
    const hasSellData = prevDevice ? !!prevDevice.sellerId : false

    SubmitRmaService.setDevice(device)
    useSubmitRmaStore.setState({
      supportsUICare: params.supportsUICare ?? false,
      manualUICare: params.manualUICare ?? false,
      seller: device?.seller ?? null,
      hasSellData,
      kits: params.kits || [],
      kit: null,
      kitDevices: [],
    })

    if (params.kits && params.kits.length === 1) {
      const firstKit = params.kits[0]
      if (firstKit.sku === device?.name) {
        SubmitRmaService.selectKit(firstKit.sku)
      }
    }
  },

  selectKit(kitSkuNew: string) {
    const { kits, device: deviceRaw } = useSubmitRmaStore.getState()

    const device = deviceRaw!

    if (kitSkuNew === NotPartOfKitOption) {
      useSubmitRmaStore.setState({
        kit: {
          displayReplaceSection: false,
          id: -1,
          products: [],
          sku: NotPartOfKitOption,
        },
        kitDevices: [],
      })
      return
    }

    let kitDevices: KitReturnDevice[] = []

    const selectedKit = kits.find((entry) => entry.sku === kitSkuNew) || null
    if (selectedKit) {
      kitDevices = selectedKit.products
        .filter((entry) => entry.type === KitType.device)
        .map(
          (entry, index): KitReturnDevice => ({
            deviceId: null,
            index,
            loading: false,
            mac: '',
            sku: entry.sku,
            errorBe: '',
            errorFe: '',
            hidden: false,
          }),
        )
    }

    for (const kitDevice of kitDevices) {
      if (kitDevice.sku === device.coreItemCode) {
        kitDevice.deviceId = device.id
        kitDevice.mac = device.mac || ''
        kitDevice.hidden = true
        break
      }
    }

    useSubmitRmaStore.setState({
      kit: selectedKit,
      kitDevices,
    })
  },

  setDevice(device: Device | null) {
    const { hasSellData, manualUICare } = useSubmitRmaStore.getState()

    const isUnifiCare = device?.isUnifiCare || false
    const isUnifiCareDistributor = isUnifiCare && !manualUICare && !device?.isSoldByUbiquiti

    const isProofOfPurchaseNeeded = device ? isProofOfPurchaseRequired(device) : false
    const isDevicePhotoNeeded = device
      ? isUnifiCareDistributor || (manualUICare && (hasSellData || !device.mac || !device.dateCode))
      : false

    useSubmitRmaStore.setState({
      device,
      isProofOfPurchaseNeeded,
      isDevicePhotoNeeded,
    })
  },

  setSeller: (seller: Company | null, isOriginalSeller: boolean) => {
    useSubmitRmaStore.setState({ seller, isOriginalSeller })
  },

  updateSeller: (seller: Company | null) => {
    const { isOriginalSeller } = useSubmitRmaStore.getState()

    useSubmitRmaStore.setState({
      seller,
      isOriginalSeller,
    })
  },

  setInvalidShippingAddress: (isInvalidShippingAddress: boolean) => {
    useSubmitRmaStore.setState({
      isInvalidShippingAddress,
    })
  },

  isExternalAddressValidation() {
    const { shippingAddress, device } = useSubmitRmaStore.getState()

    const isAdvancedFlow = device?.isHandledByUbiquiti && device?.isAdvancedFlowAvailable
    const needsUPSValidation = isAdvancedFlow && (shippingAddress?.country === 'US' || !USE_FEDEX)
    const needsFedexValidation =
      isAdvancedFlow &&
      shippingAddress?.country &&
      FEDEX_ADDRESS_VALIDATION_COUNTRIES.includes(shippingAddress.country as CountryCode)

    return needsUPSValidation || needsFedexValidation
  },

  canStartFlow() {
    return KitReturnService.canStartFlow()
  },

  getKitReturnMac(macIndex: number) {
    const { kitDevices } = useSubmitRmaStore.getState()

    const mac = kitDevices[macIndex]?.mac || ''

    return mac
  },
}
